import React from "react";

import "../styles/app.scss";
import "../styles/index.css";
import "../styles/customTheme.scss";
// import MaintenancePage from "../pages/maintenance";
import MainLayout from "../components/layouts/main";
import Script from "next/script";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  // return <MaintenancePage />;
  if (Component.noLayout) {
    return (
      <>
        <Component {...pageProps} />
        <Script async defer data-domain="notion-widgets.com" src="https://plausible.io/js/script.tagged-events.js" />
      </>
    );
  }
  return <MainLayout session={session} Component={<Component {...pageProps} />} />;
}

export default MyApp;
