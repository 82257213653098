import React from "react";

import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import toastr from "toastr";

import AppContextProvider from "../../contexts/appContext/app-context-provider";
import Layout from "../../components/common/layout";

import "toastr/build/toastr.min.css";
import "react-datetime/css/react-datetime.css";
import FeaturebaseApp from "../common/featurebase/featurebase";
import FacebookPixel from "../common/facebook-pixel";

toastr.options = {
  closeButton: true,
  progressBar: true,
  newestOnTop: true,
  positionClass: "toast-top-center",
  timeOut: 5000,
};

export default function MainLayout({ session, Component }) {
  return (
    <SessionProvider session={session}>
      <AppContextProvider>
        <Layout>
          <>
            <div style={{ minHeight: "calc(100vh - 60px)" }}>{Component}</div>
            <Script
              id="helpscout-widget"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
              ! function (e, t, n) {
                function a() {
                   var e = t.getElementsByTagName("script")[0],
                      n = t.createElement("script");
                   n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
                }
                if (e.Beacon = n = function (t, n, a) {
                      e.Beacon.readyQueue.push({
                         method: t,
                         options: n,
                         data: a
                      })
                   }, n.readyQueue = [], "complete" === t.readyState) return a();
                e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
             }(window, document, window.Beacon || function () {});
             window.Beacon('init', 'e5e3bb96-cac2-4b08-add5-c01f3407b2a4');
             `,
              }}
            />
            <Script async defer src="https://tally.so/widgets/embed.js" />
            <Script
              async
              defer
              data-domain="notion-widgets.com"
              src="https://plausible.io/js/script.tagged-events.js"
            />
            <FacebookPixel />
            <FeaturebaseApp />
            {/* </Layout> */}
          </>
        </Layout>
      </AppContextProvider>
    </SessionProvider>
  );
}
