export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;

export const pageview = () => {
  window.fbq("track", "PageView");
};

// Track specific events
export const event = (name, options = {}) => {
  window.fbq("track", name, options);
};

export const useFbInitiateCheckout = () => {
  window.fbq("track", "InitiateCheckout");
};

function convertCurrencySymbolToIso(symbol) {
  const currencyMap = {
    "€": "EUR",
    $: "USD",
    "£": "GBP",
    CHF: "CHF",
  };

  return currencyMap[symbol] || "USD"; // Default to USD if symbol not found
}

export const useFbSubscribe = (currencySymbol, value) => {
  // Convert string value to float and ensure 2 decimal places
  const numericValue = Number.parseFloat(value).toFixed(2);

  // Convert currency symbol to ISO code
  const currencyCode = convertCurrencySymbolToIso(currencySymbol);

  window.fbq("track", "Subscribe", {
    value: numericValue,
    currency: currencyCode,
  });
};
